exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-templates-archive-list-js": () => import("./../../../src/templates/archive-list.js" /* webpackChunkName: "component---src-templates-archive-list-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-gameboy-template-js": () => import("./../../../src/templates/gameboy-template.js" /* webpackChunkName: "component---src-templates-gameboy-template-js" */),
  "component---src-templates-gameboys-list-js": () => import("./../../../src/templates/gameboys-list.js" /* webpackChunkName: "component---src-templates-gameboys-list-js" */)
}

